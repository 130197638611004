import * as React from 'react'
import { DateTime, DurationLike } from 'luxon'

/**
 * Hook for common time based logic. This exposes a show boolean and hideFor function which accepts a luxon duration like object ie { days: 1 }.
 * @example const { show, hideFor } = useTimedContent(storageKey)
 * @example hideFor({ days: 10 })
 */
export const useTimedContent = (storageKey: string) => {
  const [show, setShow] = React.useState(false)

  React.useEffect(() => {
    const storageValue = localStorage.getItem(storageKey)

    if (storageValue === 'forever') return

    if (typeof storageValue !== 'string') {
      setShow(true)
      return
    }

    const now = DateTime.now().toISO()

    const expiryDatePassed =
      DateTime.fromISO(storageValue) < DateTime.fromISO(now)

    if (expiryDatePassed) setShow(true)
  })

  const hideFor = (duration: DurationLike | 'forever') => {
    if (duration === 'forever') {
      localStorage.setItem(storageKey, 'forever')
    } else {
      const resetDate = DateTime.now().plus(duration)
      localStorage.setItem(storageKey, resetDate.toISO())
    }

    setShow(false)
  }

  return {
    show,
    hideFor,
  }
}
